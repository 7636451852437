/*! 2025-2-14 17:56:46 */
[data-v-750751bd]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.sum[data-v-750751bd]{margin-top:10px;text-align:right}.sum span[data-v-750751bd]{margin-right:1px}.sum span.sum1[data-v-750751bd]{margin-right:15px}.sum span.sum2[data-v-750751bd]{margin-right:20px}.bom[data-v-750751bd]{width:100%;height:100%;padding-bottom:60px;position:relative}.bom .L[data-v-750751bd]{width:58%;padding:0 10px 0 20px}.bom .M[data-v-750751bd]{display:flex;align-items:center}.bom .R[data-v-750751bd]{width:calc(42% - 50px);padding:0 20px}.bom .btns[data-v-750751bd]{position:absolute;bottom:0;left:0;width:100%;height:60px;text-align:right;padding:14px 20px;border-top:1px solid #ededed}.bom .el-tabs .el-button[data-v-750751bd]{height:34px !important}.bom .el-tabs .el-table[data-v-750751bd]{height:calc(100vh - 220px);overflow-y:auto}.bom .el-tabs .el-pagination[data-v-750751bd]{height:40px}.bom .el-form[data-v-750751bd]{height:calc(100vh - 149px);overflow-y:auto}.bom .img-wrap[data-v-750751bd]{position:relative;padding:3px 0;width:50px;margin:0 auto}.bom .img-wrap .el-image[data-v-750751bd]{width:50px;height:50px;-o-object-fit:cover;object-fit:cover;display:block}.bom .img-wrap .el-badge[data-v-750751bd]{position:absolute;top:3px;right:-10px;z-index:2}

[data-v-5bee4706]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.page-header[data-v-5bee4706]{height:33px}[data-v-5bee4706] .page-header .el-form-item{margin-bottom:0}.divider[data-v-5bee4706]{height:1px;background-color:#f5f7fa;margin:9px 0 15px 0}.mb30[data-v-5bee4706]{margin-bottom:30px}.mt30[data-v-5bee4706]{margin-top:30px}.mb20[data-v-5bee4706]{margin-bottom:20px}.mt20[data-v-5bee4706]{margin-top:20px}.mb15[data-v-5bee4706]{margin-bottom:15px}.mt15[data-v-5bee4706]{margin-top:15px}.table-container[data-v-5bee4706]{padding:20px 15px !important}.operation[data-v-5bee4706]{display:flex;justify-content:space-evenly}.operation i[data-v-5bee4706]{font-size:20px}.error-info[data-v-5bee4706]{color:#ff4d4f;font-size:12px;line-height:1;position:relative;padding-top:5px}.pointer[data-v-5bee4706]{cursor:pointer}[data-v-5bee4706] .el-input-number--small{width:100px !important}[data-v-5bee4706] .el-input-number--small .el-input__inner{padding:0 10px !important}[data-v-5bee4706] .el-input-number--small .el-input-number__increase,[data-v-5bee4706] .el-input-number--small .el-input-number__decrease{display:none !important}[data-v-5bee4706] .el-input-number--small.error .el-input__inner{border-color:#ff4d4f}.lot-wrapper[data-v-5bee4706]{display:flex;align-items:center}.lot-wrapper .el-input[data-v-5bee4706]{flex:1}.lot-wrapper .el-icon-circle-plus-outline[data-v-5bee4706]{margin-left:5px;cursor:pointer;font-size:20px;color:#2882ff}


.outbound .el-select-dropdown__item {
  width: 200px;
}
.el-tooltip__popper {
  max-width: 400px;
}


/*# sourceMappingURL=chunk-03224f74.52219640.css.map*/